import { put, takeLatest, call, delay } from '@redux-saga/core/effects';
import { PROPOSAL_BIO_ACTIONS } from "redux/constants";
import { doAxiosRequest } from 'config-axios';
import CONFIG from 'config';
import { proposalBioAcknowledgementPending,proposalBioAcknowledgementSuccess, proposalBioAcknowledgementFailure , proposalBioPollingAPIStart, proposalBioPollingAPISuccess, proposalBioPollingAPIFailure} from "./proposalBio.actions";

export function* callAcknowledgementAPI(action) {
  const { payload } = action;

    try {
      const { XA, API_URL: { PROPOSAL_BIO_ACKNOWLEDGE } } = CONFIG;
      console.log("Acknowledgement API URL:", PROPOSAL_BIO_ACKNOWLEDGE);

      const axiosConfig = {
          method: 'POST',
          endpoint: PROPOSAL_BIO_ACKNOWLEDGE,
          params: payload,
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': XA,
          }
      };

      const response = yield call(doAxiosRequest, axiosConfig);

      const status = response?.error?.code || response?.status;
      const requestId = response?.requestId;

      if (status === '200' && requestId) {
        yield put(proposalBioAcknowledgementSuccess(requestId));
          yield put(proposalBioPollingAPIStart(requestId));
      } else {
        const errorMessage = response?.error?.message || "Acknowledgement failed";
          yield put(proposalBioAcknowledgementFailure(errorMessage));
      }
  }
  catch (error) {
      yield put(proposalBioAcknowledgementFailure(error.toString()));
  }
}

export function* callPollingAPI(action) {
  try {
    const { requestId } = action.payload;
    const maxAttempts =  CONFIG.MAX_PROPOSAL_BIO_POLL; 
    const delayDuration = CONFIG.MAX_PROPOSAL_BIO_DELAY; 

    for (let attempts = 0; attempts < maxAttempts; attempts++) {
      const { API_URL: { PROPOSAL_BIO_POLLING } } = CONFIG;
      const pollingUrl = PROPOSAL_BIO_POLLING(requestId);

      const axiosConfig = {
        method: 'GET',
        endpoint: pollingUrl,
        headers: {
            'x-api-key': CONFIG.XA
        }
      };
      const response = yield call(doAxiosRequest, axiosConfig);
      const status = response?.status?.toLowerCase();
      if (status === 'completed') {
            yield put(proposalBioPollingAPISuccess(response));
            return;
      } else if (status === 'inprogress' || status === 'requested') {
        yield delay(delayDuration);
      } else {
          yield put(proposalBioPollingAPIFailure("Polling failed"));
          return;
      }
    }

    yield put(proposalBioPollingAPIFailure( "Polling limit reached without completion"));
  } catch (error) {
    yield put(proposalBioPollingAPIFailure(error.toString()));
  }
}

export default function* proposalBioSaga() {
  yield takeLatest( PROPOSAL_BIO_ACTIONS.PROPOSAL_BIO_ACKNOWLEDGEMENT_API_PENDING, callAcknowledgementAPI);
  yield takeLatest(PROPOSAL_BIO_ACTIONS.PROPOSAL_BIO_POLLING_API_START, callPollingAPI);
}
