import {
  MATERIALS_STATUS
} from 'redux/constants';
import { doAxiosRequest } from 'config-axios';
import CONFIG from 'config';
import { put, takeLatest, call, delay, select } from '@redux-saga/core/effects';
import { moduleFailure } from "redux/global.action.js";
import { materialsStatusPending, materialsStatusSuccess, materialsStatusFailure } from './materialstatus.actions';

export function* getMaterialStatus(action) {
  const { payload } = action;
  try {
    yield put(materialsStatusPending());
    const materialStatusResponse = yield call(doAxiosRequest, {
      method: 'POST',
      endpoint: CONFIG.API_URL.GET_MATERIAL_STATUS,
      params: payload,
      headers: {
        'x-api-key': CONFIG.XA
      }
    });
    yield put(materialsStatusSuccess(materialStatusResponse.data));
  }
  catch (error) {
    yield put(materialsStatusFailure(error));
  }
}

export default function* materialStatusSaga() {
  try {
    yield takeLatest(MATERIALS_STATUS.GET_MATERIALS_STATUS, getMaterialStatus);
  } catch (error) {
    yield put(materialsStatusFailure(error, materialStatusSaga))
  }
}