import { PROPOSAL_BIO_ACTIONS } from "redux/constants";
  
  export const proposalBioAcknowledgementPending = (payload) => ({
    type: PROPOSAL_BIO_ACTIONS.PROPOSAL_BIO_ACKNOWLEDGEMENT_API_PENDING,
    payload,
  });
  
  export const proposalBioAcknowledgementSuccess = (requestId) => ({
    type: PROPOSAL_BIO_ACTIONS.PROPOSAL_BIO_ACKNOWLEDGEMENT_API_SUCCESS,
    payload: {requestId},
  });
  
  export const proposalBioAcknowledgementFailure = (error) => ({
    type: PROPOSAL_BIO_ACTIONS.PROPOSAL_BIO_ACKNOWLEDGEMENT_API_FAILURE,
    error,
  });
  
  export const proposalBioPollingAPIStart = (requestId) => ({
    type: PROPOSAL_BIO_ACTIONS.PROPOSAL_BIO_POLLING_API_START,
    payload: { requestId },
});

export const proposalBioPollingAPISuccess = (res) => ({
    type: PROPOSAL_BIO_ACTIONS.PROPOSAL_BIO_POLLING_API_SUCCESS,
    payload: res,
});

export const proposalBioPollingAPIFailure = (error) => ({
    type: PROPOSAL_BIO_ACTIONS.PROPOSAL_BIO_POLLING_API_FAILURE,
    payload: { error },
});

export const updateExperienceFields = (fields) => ({
	type: PROPOSAL_BIO_ACTIONS.UPDATE_EXPERIENCE_FIELDS,
	payload: fields,
});

