import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {FCG_ProposalBiov1} from "containers/FCG_ProposalBio/FCG_ProposaBio";
import ProposalBioSlide from "containers/ProposalBioSlide/ProposalBioSlide";
import { selectors as proposalBioSelectors } from 'redux/reducers/proposalBio/proposalBio_reducer';
import { updateExperienceFields } from 'redux/actions/proposalBio/proposalBio.actions';

const ProposalBioContainer = ({ selectedTemplateTitle, handleClose }) => {
	const [isGrayscale, setIsGrayscale] = useState(true);
	const [isSquareShape, setIsSquareShape] = useState(true);
	const [proposalPeopleData, setProposalPeopleData] = useState(null);

	const dispatch = useDispatch();

	let experienceFields = useSelector(proposalBioSelectors.getExperienceFields);

	const handleToggleGrayscale = () => {
		setIsGrayscale((prev) => !prev);
	};

	const handleToggleShape = () => {
		setIsSquareShape((prev) => !prev);
	};

	const handleClearProposalData = () => {
		setProposalPeopleData(null);
		setIsGrayscale(false); 
		setIsSquareShape(false);
		dispatch(updateExperienceFields({ 
			role: "", 
			summaryRelevantExperience: "", 
			relevantExperience: "", 
			priorExperienceAndEducation: "", 
			profileSummary: "" 
		}));
	};

	return (
		<div className="ProposalBioContainer">
			<FCG_ProposalBiov1
				selectedTemplateTitle={selectedTemplateTitle}
				handleClose={handleClose}
				handleClearProposalData={handleClearProposalData}
				onToggleGrayscale={handleToggleGrayscale}
				onToggleShape={handleToggleShape}
				isGrayscale={isGrayscale}
				isSquareShape={isSquareShape}
				onPreviewData={setProposalPeopleData}
				proposalPeopleData={proposalPeopleData} 
				experienceFields={experienceFields} 
			/>
			<ProposalBioSlide
				peopleData={proposalPeopleData}
				isGrayscale={isGrayscale}
				isSquareShape={isSquareShape}
			/>
		</div>
	);
};

export default ProposalBioContainer;
