import React, { useState } from "react";
import PropTypes from "prop-types";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";

import { KnowledgeEntityActions } from "components/KnowledgeEntityActions";
import { InfoPopup } from 'components/shared/InfoPopup/InfoPopup';
import Image from "components/shared/Image";
import pageIcon from "assets/images/page.png";
import bundleIcon from "assets/images/collection.svg"
import CONFIG from "config";
import LABELS from "labels";
import GLOBAL_CONSTANTS from "globalConstants";
import dotsImage from 'assets/images/dots-image.svg';
import { formatTheDate, getNumberInTwoDigits, getFileIcon } from "utils/helpers/helpers";
import "./KnowledgeEntity.scss";

export const KnowledgeEntity = (props) => {
  const [isInfoPopupModalOpen, setIsInfoPopupModalOpen] = useState(false);
  const closeEllipsesModal = (isClicked) => {
    setIsInfoPopupModalOpen(isClicked);
  }

  const resetInfoModalPopupState = () => {
    setIsInfoPopupModalOpen(false);
  }
  const {
    MY_NAVIGATOR: {
      ENTITY_TYPES,
      VERSION,
      DOWNLOADS,
      EMPTY_VALUE,
      STATUS_FILTERS: {
        PUBLISHED,
      }
    },
    BUNDLE_TYPE: { TOPIC_COLLECTION }
  } = LABELS;
  const {
    entity,
    entityType,
    editAction,
    deleteAction,
    previewAction,
    archiveAction,
    unpublishAction,
    isCreateEditEntitlement,
    isPublishContentEntitlement,
    isEditPublishedEntitlement,
    isSiteAdmin,
    ellipseIconVisible,
    hideStatus,
    isArchiveVisible,
    isContributionPage,
    onCloneButtonClick,
    isCloneVisible
  } = props;
  const {
    KNOWLEDGE_TYPE_KEYS: {
      BUNDLES,
      COLLECTIONS
    },
    KNOWLEDGE_STATUS_MAP,
    CP_UI_DATE_FORMAT
  } = CONFIG;
  const { topicPages } = entity;
  const { description, status, title, updatedDate, version, logicalDownloadAllTime, fileName, publishdate, filename } = entity;
  const localUpdatedDate = !!updatedDate || !!publishdate ? formatTheDate(updatedDate || publishdate, CP_UI_DATE_FORMAT) : "";
  const isEditHidden = (entity?.status === PUBLISHED && (!isEditPublishedEntitlement || (entity?.bundleType && entity?.bundleType?.name === TOPIC_COLLECTION && !isSiteAdmin)));
  const fileIcon = status === "InQueue" ? getFileIcon(filename) : getFileIcon(fileName);

  const getIconSrc = (status, entity, entityType, fileIcon, bundleIcon, pageIcon) => {
    if (status === "InQueue") {
      if (entity.materialtype === "Knowledge Bundle" || entity.materialtype === "Topic Collections") {
        return bundleIcon;
      } else if (entity.materialtype === "Knowledge Page") {
        return fileIcon;
      }
    } else if (entityType === BUNDLES || entityType === COLLECTIONS) {
      return bundleIcon;
    }
    return fileIcon ? fileIcon : pageIcon;
  };

  const getEntityType = (status, entity, entityType) => {
    if (status === "InQueue") {
      if (entity.materialtype === "Knowledge Page") {
        return ENTITY_TYPES.KP;
      } else if (entity.materialtype === "Knowledge Bundle") {
        return ENTITY_TYPES.KB;
      } else if (entity.materialtype === "Topic Collections") {
        return ENTITY_TYPES.TC;
      } else {
        return ENTITY_TYPES.KP;
      }
    } else if (entityType === BUNDLES) {
      return ENTITY_TYPES.KB;
    } else if (entityType === COLLECTIONS) {
      return ENTITY_TYPES.TC;
    } else {
      return ENTITY_TYPES.KP;
    }
  };

  return (
    <div className="kent">
      <div className="kent__row1">
        <div className="kent__col1">
          <div className="kent__icontype">
            <div className="kent__icon-wrapper">
              <Image
                className="kent__icon"
                src={getIconSrc(status, entity, entityType, fileIcon, bundleIcon, pageIcon)}
                alt="icon"
              />
            </div>
            <div className="kent__pill-type">
              {getEntityType(status, entity, entityType)}
            </div>
          </div>

          <div className="kent__date">
            {localUpdatedDate}
            {
              !!version && <span> | V{("0" + version).slice(-2)}</span>
            }
          </div>
          {
            ((!!logicalDownloadAllTime || Number(logicalDownloadAllTime) === 0) && status === PUBLISHED) &&
            <div className="kent__version">
              <span>{DOWNLOADS}</span>
              <span className="kent__version-number">{Number(logicalDownloadAllTime) === 0 ? EMPTY_VALUE : getNumberInTwoDigits(Number(logicalDownloadAllTime))}</span>
            </div>
          }

        </div>
        <div className="kent__col2">
          {topicPages && topicPages.length > 0 && topicPages[0].topicId && <div class="kent__toppill">{topicPages[0].topicName}</div>}
          <div className="kent__title" title={title}>
            {title}
          </div>
          <div className="kent__description" title={description}>
            <HTMLEllipsis
              unsafeHTML={description}
              maxLine="2"
            />
          </div>
        </div>

        <div className="kent__col3">
          {!hideStatus && <div>
            <div className={`kent__pill-status--${status.toLowerCase()}`}>
              {KNOWLEDGE_STATUS_MAP[status]}
            </div>
          </div>
          }
          <div className="kent__actions">
            {(status === PUBLISHED) &&
              <KnowledgeEntityActions
                isEditVisible={!isEditHidden}
                isArchiveVisible={isArchiveVisible}
                isPreviewVisible={false}
                flavor={GLOBAL_CONSTANTS.KNOWLEDGE_ACTION_FLAVOR.REGULAR}
                entity={entity}
                entityType={entityType}
                editAction={editAction}
                deleteAction={deleteAction}
                previewAction={previewAction}
                unpublishAction={unpublishAction}
                archiveAction={archiveAction}
                isCreateEditEntitlement={isCreateEditEntitlement}
                isPublishContentEntitlement={isPublishContentEntitlement}
                isEditPublishedEntitlement={isEditPublishedEntitlement}
                isSiteAdmin={isSiteAdmin}
                isContributionPage={isContributionPage}
              />
            }
            {ellipseIconVisible &&
              <InfoPopup
                tooltipPosition="bottom"
                tooltipId="case_more_options"
                popupVerticalPosition="bottom"
                popupHorizontalPosition="left"
                withTooltip={false}
                isInfoPopupModalOpen={isInfoPopupModalOpen}
                resetInfoPopuModalState={resetInfoModalPopupState}
                offset="{'top': 10}"
                trigger={
                  <Image className="caseConsumptionActions__dots" src={dotsImage} />
                }
              >
                <KnowledgeEntityActions
                  isEditVisible={false}
                  flavor={GLOBAL_CONSTANTS.KNOWLEDGE_ACTION_FLAVOR.LIST}
                  entity={entity}
                  entityType={entityType}
                  editAction={editAction}
                  deleteAction={deleteAction}
                  previewAction={previewAction}
                  archiveAction={archiveAction}
                  unpublishAction={unpublishAction}
                  isCreateEditEntitlement={isCreateEditEntitlement}
                  isPublishContentEntitlement={isPublishContentEntitlement}
                  isEditPublishedEntitlement={isEditPublishedEntitlement}
                  isSiteAdmin={isSiteAdmin}
                  isContributionPage={isContributionPage}
                  onCloneButtonClick={onCloneButtonClick}
                  isCloneVisible={isCloneVisible}
                  isTextClicked={closeEllipsesModal}
                />
              </InfoPopup>
            }
          </div>
        </div>
      </div>
    </div>
  )
};

KnowledgeEntity.defaultProps = {
  entity: {
    description: "",
    status: "",
    title: "",
    updatedDate: "",
    logicalDownloadAllTime: 0
  },
  entityType: "",
  isEditPublishedEntitlement: false,
  isCreateEditEntitlement: false,
  isSiteAdmin: false,
  isDraft: false,
  ellipseIconVisible: false,
  hideStatus: false,
  isContributionPage: false
};

KnowledgeEntity.propTypes = {
  entity: PropTypes.object.isRequired,
  entityType: PropTypes.string.isRequired,
  editAction: PropTypes.object.isRequired,
  deleteAction: PropTypes.object.isRequired,
  previewAction: PropTypes.object.isRequired,
  archiveAction: PropTypes.object.isRequired,
  unpublishAction: PropTypes.object.isRequired,
  isSiteAdmin: PropTypes.bool,
  isContributionPage: PropTypes.bool,
  isDraft: PropTypes.bool,
  ellipseIconVisible: PropTypes.bool,
  hideStatus: PropTypes.bool,
};
